import request from '@/utils/request'


// 查询用户信息列表
export function listUser(query) {
  return request({
    url: '/biz/user/getAllUser',
    method: 'get',
    params: query
  })
}
// 查询红娘



// 查询用户信息分页
export function pageUser(query) {
  return request({
    url: '/biz/user/page',
    method: 'get',
    params: query
  })
}

// 查询用户信息详细
export function getUser(data) {
  return request({
    url: '/biz/user/detail',
    method: 'get',
    params: data
  })
}

// 新增用户信息
export function addUser(data) {
  return request({
    url: '/biz/user/add',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUser(data) {
  return request({
    url: '/biz/user/edit',
    method: 'post',
    data: data
  })
}

// 删除用户信息
export function delUser(data) {
  return request({
    url: '/biz/user/delete',
    method: 'post',
    data: data
  })
}
//设置线下会员
export function setLineVip(data) {
  return request({
    url: '/biz/user/setLineVip',
    method: 'post',
    data: data
  })
}
export function unSetLineVip(data) {
  return request({
    url: '/biz/user/unSetLineVip',
    method: 'post',
    data: data
  })
}

//设置高质量会员
export function setHighQuality(data) {
  return request({
    url: '/biz/user/setHighQuality',
    method: 'post',
    data: data
  })
}
export function unSetHighQuality(data) {
  return request({
    url: '/biz/user/unSetHighQuality',
    method: 'post',
    data: data
  })
}

export function getUserData (value) {
  return request({
    url: '/biz/user/getUserData',
    method: 'get',
    params:value
  })
}

export function getDiomnData (value) {
  return request({
    url: '/biz/user/getDiomnData',
    method: 'get',
    params:value
  })
}

export function getBuyData (value) {
  return request({
    url: '/biz/user/getBuyData',
    method: 'get',
    params:value
  })
}

export function getVipData (value) {
  return request({
    url: '/biz/user/getVipData',
    method: 'get',
    params:value
  })
}

export function getUserDayData () {
  return request({
    url: '/biz/user/getUserDayData',
    method: 'get'
  })
}
//折线图
export function getInstutionByDay () {
  return request({
    url: '/biz/user/getInstutionByDay',
    method: 'get'
  })
}
//饼状图
export function getRuioDataByDay () {
  return request({
    url: '/biz/user/getRuioDataByDay',
    method: 'get'
  })
}
export function getStorePage (query) {
  return request({
    url: '/biz/user/getStorePage',
    method: 'get',
    params: query
  })
}
